/***************
混入（keepalive缓存页面）
***************/
import GlobalData from "@/js/GlobalData";
export default {
  data() {
    return {
      frist_load: true, // 判断是否是第一次加载，第一次加载则不执行activated
    }
  },
  beforeMount() {
    let _this: any = this
    if (_this.init) { _this.init() }
  },
  deactivated() {
    let _this: any = this
    _this.frist_load = false
  },
  // keepalive适应，不需要缓存时重刷数据
  activated() {
    let _this: any = this
    if (_this.frist_load) { return }
    if (GlobalData.refresh_keep_alive) { // 需要刷新页面
      if (_this.init) { _this.init() }
    } else { // 不需要刷新则重设滚动高度
      let route = String(_this.$route.name);
      let scroll = _this.$globalData.scroll_keep_alive[route]
      let app = window.document.querySelector('#app-container')
      let list = window.document.querySelector('#app-list')
      if (list) {
        list.scrollTop = scroll
      } else if (app) { app.scrollTop = scroll }
    }
    GlobalData.refresh_keep_alive = true
  },
}