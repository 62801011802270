
























































































































import { Component, Vue } from "vue-property-decorator";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class ShopList extends Vue {
  public list: any[] = []; // 数据的列表
  public nav_bar = 0; // 当前选择的导航栏
  public nav_list = []; // 导航栏的列表
  public done = false; // 判断是否请求完成(是否显示“您还没有申请门店”)

  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
    this.list.push(...list);
  }

  // 获取店铺列表
  getShopList() {
    let _this = this;
    _this.done = false;
    this.$api.request({
      url: "common/shop/shop-list",
      success(res) {
        _this.done = true;
        _this.list = res.data;
      }
    });
  }

  init() {
    this.list = [];
    this.nav_bar = 0;
    this.nav_list = [];
    this.done = false;
    this.getShopList();
  }
}
